import { auth, db } from "@/firebase"
import { onAuthStateChanged, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { query, orderBy, startAt, startAfter, collection, getDocs, addDoc, setDoc, doc, limit, onSnapshot, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { toast } from 'vue3-toastify';

export default ({
    namespaced: true,
    state: {
        userId: '',
        // profileInfo: {}
    },
    getters: {
        getUserId(state) {
            return state.userId;
        },
        getDiscount(state) {
            return "<b>রেজিস্ট্রেশন:</b> রেজিস্ট্রেশন ফি <b>1000 TK</b>. রেজিস্ট্রেশন এর ওপর 50% ডিসকাউন্ট পেতে <b>UC50OFF</b> কোডটি (প্রমো কোড) ব্যবহার করুন। অফারটি <b>15-10-2024</b> তারিখ পর্যন্ত থাকবে।"
        }
        // getProfileInfo(state) {
        //     return state.profileInfo;
        // },
        // getUserExpiredInfo(state) {
        //     return state.profileInfo.planExpiredOn;
        // }
    },
    mutations: {
        updateUserStatus(state, status) {
            state.userId = status;
        },
    },
    actions: {
        // checkAuthentication({ commit, state }) {
        //     return new Promise((resolve, reject) => {
        //         onAuthStateChanged(auth, (user) => {
        //             if (user) {
        //                 commit('updateUserStatus', user.uid)
        //                 // this.dispatch("auth/getProfileInfo")
        //                 resolve(true)
        //             } else {
        //                 commit('updateUserStatus', '')
        //                 reject(false)
        //             }
        //         });
        //     })
        // },
        // createAccount({ commit }, userInfo) {
        //     return new Promise((resolve, reject) => {
        //         createUserWithEmailAndPassword(auth, userInfo.email, userInfo.password)
        //             .then((response) => {
        //                 resolve(response)
        //                 // toast("Your account created and logged in successfully.", {
        //                 //     "theme": "colored",
        //                 //     "type": "success",
        //                 //     "autoClose": 3000,
        //                 //     "transition": "flip",
        //                 //     "dangerouslyHTMLString": true
        //                 // })
                        
        //                 // setTimeout(() => {
        //                 //     resolve(response)
        //                 // }, 3000);
        //             })
        //             .catch((error) => {
        //                 reject(error)
        //                 if (error.code == 'auth/email-already-in-use') {
        //                     toast("This email is already in use.", {
        //                         "theme": "colored",
        //                         "type": "error",
        //                         "autoClose": 3000,
        //                         "transition": "flip",
        //                         "dangerouslyHTMLString": true
        //                     })
        //                 } else {
        //                     toast("Something went wrong, try again later.", {
        //                         "theme": "colored",
        //                         "type": "error",
        //                         "autoClose": 3000,
        //                         "transition": "flip",
        //                         "dangerouslyHTMLString": true
        //                     })
        //                 }
        //             });
        //     })
        // },
        // signIn({ commit }, userInfo) {
        //     return new Promise((resolve, reject) => {
        //         signInWithEmailAndPassword(auth, userInfo.email, userInfo.password)
        //             .then((response) => {
        //                 commit('updateUserStatus', response.user.uid)
        //                 // toast("logged in successfully.", {
        //                 //     "theme": "colored",
        //                 //     "type": "success",
        //                 //     "autoClose": 3500,
        //                 //     "transition": "flip",
        //                 //     "dangerouslyHTMLString": true
        //                 // })
        //                 resolve(response)
        //             })
        //             .catch((error) => {
        //                 commit('updateUserStatus', '')
        //                 toast(error.code == 'auth/invalid-login-credentials' ? "Email or password is wrong." : "Something went wrong, try again later.", {
        //                     "theme": "colored",
        //                     "type": "error",
        //                     "autoClose": 2000,
        //                     "transition": "flip",
        //                     "dangerouslyHTMLString": true
        //                 })
        //                 reject(error)
        //             });
        //     })
        // },
        // signOut({ commit }) {
        //     return new Promise((resolve, reject) => {
        //         signOut(auth)
        //             .then((response) => {
        //                 commit('updateUserStatus', '')
        //                 resolve(false)
        //             })
        //             .catch((error) => {
        //                 toast("Something went wrong, try again later.", {
        //                     "theme": "colored",
        //                     "type": "error",
        //                     "autoClose": 2000,
        //                     "transition": "flip",
        //                     "dangerouslyHTMLString": true
        //                 })
        //                 reject(true)
        //             });
        //     })
        // },
        saveOrderInfo({commit, state}, info) {
            return new Promise((resolve, reject) => {
                addDoc(collection(db, "orders"), info)
                .then((success) => {
                    resolve(true)
                })
                .catch((error) => {
                    reject(false)
                })
            })
        },
        // renewPlan({commit, state}, info) {
        //     return new Promise((resolve, reject) => {
        //         updateDoc(doc(db, "profiles", state.userId), {planId: info.planId, planExpiredOn: info.planExpiredOn})
        //         .then((success) => {
        //             addDoc(collection(db, "orders"), {
        //                 userId: state.userId,
        //                 name: info.name,
        //                 contact: info.contact,
        //                 email: info.email,
        //                 planId: info.planId,
        //                 transectionId: info.transectionId
        //             })
        //             .then((success) => {
        //                 resolve(true)
        //             })
        //             .catch((error) => {
        //                 reject(false)
        //             })
        //         })
        //         .catch((error) => {
        //             reject(false)
        //         })
        //     })
        // },
        // updateProfileInfo({commit, state}, info) {
        //     return new Promise((resolve, reject) => {
        //         updateDoc(doc(db, "profiles", state.userId), info)
        //         .then((success) => {
        //             resolve(true)
        //         })
        //         .catch((error) => {
        //             reject(false)
        //         })
        //     })
        // },
        // getProfileInfo({commit, state}) {
        //     onSnapshot(doc(db, "profiles", state.userId), (doc) => {
        //         if (doc.data()) {
        //             state.profileInfo = doc.data()
        //         } else {
        //             state.profileInfo = {}
        //         }
        //     })
        // }
        saveOrderInfo({commit, state}, info) {
            return new Promise((resolve, reject) => {
                addDoc(collection(db, "freetrial"), info)
                .then((success) => {
                    resolve(true)
                })
                .catch((error) => {
                    reject(false)
                })
            })
        },
    }
})
