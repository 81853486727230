<template>
    <div class="container py-5">
        <form @submit.prevent="goToConfirmOrder">
            <div class="alert alert-warning m-0 mt-5">
                <b>সফটওয়্যার একটিভ করুন:</b> আপনার unani chikitsa সফটওয়্যারটি পুনরায় একটিভ করতে <router-link to="/active-plan">এখানে</router-link> ক্লিক করুন।
            </div>
            
            <h2 class="mt-5 mb-4 title pb-3">আপনার অর্ডার সম্পূর্ণ করতে নিচের ফর্মটি পূরণ করুন।</h2>

            <div class="alert alert-success" v-html="discount"></div>

            <div class="row mt-5 mb-2">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="mb-2" for="full-name"><b>Full name</b></label>
                        <input type="text" class="form-control" id="full-name" v-model="name" required
                            placeholder="Enter your name">
                    </div>
                    <br>
                    <div class="form-group">
                        <label class="mb-2" for="phone-number"><b>Phone number</b></label>
                        <input type="text" class="form-control" id="phone-number" v-model="phoneNumber" required
                            placeholder="Enter phone number">
                    </div>
                    <!-- <br>
                    <div class="form-group">
                        <label class="mb-2" for="registration-number"><b>BBUASM Registration Number</b></label>
                        <input type="text" class="form-control" id="registration-number" v-model="BBUASMRegistrationNumber" required
                            placeholder="Enter BBUASM registration number">
                    </div> -->
                </div>
            </div>

            <div class="pb-3 pt-4">
                <div class="alert alert-success m-0 mt-3">
                    <b>নতুন Gmail তৈরি করুন:</b> আপনার সফ্টওয়্যার প্রস্তুত করতে আপনাকে আপনার জিমেইল এবং আপনার জিমেইলের পাসওয়ার্ড লিখতে হবে। তবে নতুন জিমেইল তৈরি করা ভালো।
                </div>
            </div>

            <div class="row mt-4 mb-2">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="mb-2" for="email"><b>Email address</b></label>
                        <input type="email" class="form-control" id="email" v-model="email" required
                            placeholder="Enter email address" >
                    </div>
                    <div class="form-group mt-4">
                        <label class="mb-2" for="password"><b>Password</b></label>
                        <input type="text" class="form-control" id="password" v-model="password" required
                            placeholder="Enter email password" >
                    </div>
                </div>
            </div>

            <div class="row mt-4 mb-2">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="mb-2" for="plans"><b>Package</b></label>
                        <select v-model="selectedPlan" @change="calculateTotalOrderPrice" class="form-control" required id="plans">
                            <option value="">Choose one</option>
                            <template v-for="(plan, index) in plans" :key="index">
                                <option :value="plan.planName">
                                    {{ plan.planName }}
                                </option>
                            </template>
                        </select>
                    </div>
                </div>
            </div>

            <div class="mt-5 mb-2">
                <button type="submit" class="btn btn-primary">Continue</button>
            </div>
        </form>
        
        <div v-if="confirmOrderModal" class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: block;" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-dialog-scrollable modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Payment</h1>
                        <button @click="confirmOrderModal=false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-start py-3">
                        <form @submit.prevent="onSubmit">
                            <table class="w-100">
                                <tr>
                                    <th class="pb-1">Registration Fee</th>
                                    <td class="text-end pb-1"><span v-if="registrationFee == 500" class="discount text-danger">1000 TK</span>  {{ registrationFee }} TK</td>
                                </tr>
                                <tr>
                                    <th class="pb-2">Plan: {{ selectedPlan }}</th>
                                    <td class="text-end pb-2">
                                        <template v-for="(plan, index) in plans" :key="index">
                                            <span class="d-inline" v-if="selectedPlan == plan.planName">{{ plan.price }} TK</span>
                                        </template>
                                    </td>
                                </tr>
                                <tr class="border-top">
                                    <th class="pt-2">Total</th>
                                    <td class="text-end pt-2">{{ totalOrderPrice }} TK</td>
                                </tr>
                                <tr v-if="!isPromoCodeApplied">
                                    <td colspan="2" class="text-end pt-2">
                                        <span class="promo-code" @click="isPromoCodeApplied=true" >Do you have a promo code?</span>
                                    </td>
                                </tr>
                            </table>
                            <table v-if="isPromoCodeApplied && registrationFee==1000" class="w-100 mt-3">
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="promoCode" placeholder="Enter promo code">
                                    </td>
                                    <td class="promoce-apply-btn">
                                        <button @click="applyPromoCode" type="button" class="btn btn-primary text-capitalize" >Apply</button>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <div class="alert alert-success m-0">
                                <div><b>অর্ডার নিশ্চিত করুন:</b> আপনার অর্ডার নিশ্চিত করতে নিচের নম্বরে <b>{{ totalOrderPrice }} TK</b> পাঠান। <br>Bkash / Nagad / Rocket: <b>01737354497</b> (Personal).</div>
                            </div>
                            <br>
                            <div class="form-group">
                                <label class="mb-2" for="payment-method"><b>Payment Method</b></label>
                                <select v-model="paymentMethod" class="form-control" required id="payment-method">
                                    <option value="">Choose one</option>
                                    <option value="Bkash">Bkash</option>
                                    <option value="Nagad">Nagad</option>
                                    <option value="Rocket">Rocket</option>
                                </select>
                            </div>
                            <br>
                            <div class="form-group">
                                <label class="mb-2" for="transection-id"><b>Last 3 Digits Of Your Bkash / Nagad / Rocket Number</b></label>
                                <input type="text" class="form-control" id="transection-id" v-model="transectionId" required placeholder="Enter 3 digits">
                            </div>

                            <br><br>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary text-capitalize" >Submit <span v-if="spinner" class="spinner"></span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify';

export default {
    setup() {
        const name = ref('')
        const phoneNumber = ref('')
        const BBUASMRegistrationNumber = ref('')
        const email = ref('')
        const password = ref('')
        const selectedPlan = ref('')
        const isFirstOrder = ref(true)
        const softwareUrl = ref('')
        const paymentMethod = ref('')
        const transectionId = ref('')

        const totalOrderPrice = ref(0)
        const registrationFee = ref(1000)
        const promoCode = ref('')
        const isPromoCodeApplied = ref(false)

        const spinner = ref(false)
        const confirmOrderModal = ref(false)

        const store = useStore();
        const router = useRouter();

        const discount = computed(() => store.getters['auth/getDiscount'])

        const plans = ref([
            {
                planName: '3 Months (250 TK)',
                price: 250
            },
            {
                planName: '6 Months (350 TK)',
                price: 350
            },
            {
                planName: '1 Year (450 TK)',
                price: 450
            },
            {
                planName: '5 Years (1650 TK)',
                price: 1650
            }
        ])

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
        })

        function applyPromoCode() {
            let pcode = 'UC50OFF'
            if (promoCode.value) {
                if (promoCode.value.toLowerCase() == pcode.toLowerCase()) {
                    registrationFee.value = 500
                    calculateTotalOrderPrice()
                    toast("You are getting 50% discount on registration.", {
                        "theme": "colored",
                        "type": "success",
                        "autoClose": 3500,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })
                } else {
                    toast("Wrong promo code.", {
                        "theme": "colored",
                        "type": "error",
                        "autoClose": 3000,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })
                }
            } else {
                toast("Enter promo code.", {
                    "theme": "colored",
                    "type": "error",
                    "autoClose": 3000,
                    "transition": "flip",
                    "dangerouslyHTMLString": true
                })
            }
        }

        function goToConfirmOrder() {
            confirmOrderModal.value = true
        }

        const onSubmit = () => {
            let code = localStorage.getItem("code")
            spinner.value = true

            let info = {
                name: name.value,
                phoneNumber: phoneNumber.value,
                BBUASMRegistrationNumber: BBUASMRegistrationNumber.value,
                email: email.value,
                password: password.value,
                isFirstOrder: isFirstOrder.value,
                softwareUrl: softwareUrl.value,
                selectedPlan: selectedPlan.value,
                paymentMethod: paymentMethod.value,
                transectionId: transectionId.value,
                totalOrderPrice: totalOrderPrice.value,
                status: 1,
                year: new Date().getFullYear(),
                month: new Date().getMonth()+1,
                day: new Date().getDate(),
                date: Date.now(),
                promoCode: code != null ? code : ''
            }

            store.dispatch("auth/saveOrderInfo", info)
                .then((res) => {
                    spinner.value = false

                    name.value = ''
                    phoneNumber.value = ''
                    BBUASMRegistrationNumber.value = ''
                    email.value = ''
                    password.value = ''
                    selectedPlan.value = ''
                    paymentMethod.value = ''
                    transectionId.value = ''
                    totalOrderPrice.value = 0
                    
                    router.push("/complete-new-order")
                })
                .catch((err) => {
                    spinner.value = false
                    toast("Something went wrong, try again later.", {
                        "theme": "colored",
                        "type": "error",
                        "autoClose": 3000,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })
                })
        }

        function calculateTotalOrderPrice() {
            totalOrderPrice.value = 0
            for (let index = 0; index < plans.value.length; index++) {
                if (plans.value[index].planName == selectedPlan.value) {
                    totalOrderPrice.value = plans.value[index].price
                }
            }

            totalOrderPrice.value = totalOrderPrice.value + registrationFee.value
        }

        return { discount, promoCode, isPromoCodeApplied, registrationFee, confirmOrderModal, plans, name, email, password, phoneNumber, BBUASMRegistrationNumber, isFirstOrder, softwareUrl, selectedPlan, paymentMethod, transectionId, spinner, totalOrderPrice, goToConfirmOrder, onSubmit, applyPromoCode, calculateTotalOrderPrice };
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

.title {
    font-weight: 500;
}

label, div, input, select {
    font-size: 18px;
}


.form-check {
    input {
        height: 24px !important;
        width: 24px !important;
        border: 1px solid $cs-link;
    }
    span {
        position: relative;
        top: 5px !important;
        font-weight: 600;
    }
}

.btn.btn-primary {
    padding-top: 8px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 18px;
}

.promo-code {
    color: #1791d8;
    cursor: pointer;
    padding-top: 4px !important;
}

.promoce-apply-btn {
    width: 92px;
}

.discount {
    text-decoration: line-through;
    margin-right: 5px;
}
</style>