<template>
    <div class="container py-5">
        <form @submit.prevent="onSubmit">

            <div class="alert alert-success m-0 mt-5">
                সফটওয়্যারটির সব ফীচার গুলো ৭ দিন ফ্রি ব্যবহার করতে পারবেন।
            </div>
            
            <h2 class="mt-4 mb-4 py-3 title">ফ্রি ট্রায়াল এর জন্য নিচের ফর্মটি পূরণ করুন।</h2>

            <div class="row mb-2">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="mb-2" for="full-name"><b>Full name</b></label>
                        <input type="text" class="form-control" id="full-name" v-model="name" required
                            placeholder="Enter your name">
                    </div>
                    <br>
                    <div class="form-group">
                        <label class="mb-2" for="phone-number"><b>Phone number</b></label>
                        <input type="text" class="form-control" id="phone-number" v-model="phoneNumber" required
                            placeholder="Enter phone number">
                    </div>
                    <br>
                    <div class="form-group">
                        <label class="mb-2" for="email"><b>Email address</b></label>
                        <input type="email" class="form-control" id="email" v-model="email" required
                            placeholder="Enter email address" >
                    </div>
                </div>
            </div>

            <div class="mt-5 mb-2">
                <button type="submit" class="btn btn-primary">Submit <span v-if="spinner" class="spinner"></span></button>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify';

export default {
    setup() {
        const name = ref('')
        const phoneNumber = ref('')
        const email = ref('')

        const spinner = ref(false)

        const store = useStore()
        const router = useRouter()

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
        })

        const onSubmit = () => {
            spinner.value = true

            let info = {
                name: name.value,
                phoneNumber: phoneNumber.value,
                email: email.value,
                status: 1,
                year: new Date().getFullYear(),
                month: new Date().getMonth()+1,
                day: new Date().getDate(),
                date: Date.now()
            }

            store.dispatch("auth/saveOrderInfo", info)
                .then((res) => {
                    spinner.value = false

                    name.value = ''
                    phoneNumber.value = ''
                    email.value = ''
                    router.push("/complete-free-trial")
                })
                .catch((err) => {
                    spinner.value = false
                    toast("Something went wrong, try again later.", {
                        "theme": "colored",
                        "type": "error",
                        "autoClose": 3000,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })
                })
        }

        return { name, email, phoneNumber, spinner, onSubmit };
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

.title {
    font-weight: 500;
}

label, div, input, select {
    font-size: 18px;
}


.form-check {
    input {
        height: 24px !important;
        width: 24px !important;
        border: 1px solid $cs-link;
    }
    span {
        position: relative;
        top: 5px !important;
        font-weight: 600;
    }
}

.btn.btn-primary {
    padding-top: 8px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 18px;
}
</style>