<template>
    <div class="container py-5">
        <br>
        <div class="alert alert-success my-5">
            আপনার অনুরোধ সফলভাবে গ্রহণ করা হয়েছে। আপনার সফ্টওয়্যার প্রস্তুত হতে 3 - 4 দিন সময় লাগবে। এটি প্রস্তুত হলে আমরা আপনার সাথে যোগাযোগ করব।
        </div>
        <div class="text-center">
            <router-link to="/">Go back to Home</router-link>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
    setup() {
        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
        })

        return { }
    }
};
</script>

<style lang="scss" scoped>

a, div {
    font-size: 20px;
    text-decoration: none;
}

</style>