<template>
    <div id="page-footer" class="container-fluid px-1 mt-5 pb-3">
        <div class="container pt-5 pb-2 text-body-secondary">
            <div class="row">
                <div class="col-lg-5 pb-4">
                    <a href="#" class="d-inline-flex align-items-center mb-2 text-body-emphasis text-decoration-none" aria-label="Bootstrap">
                        <img src="../../assets/logo.png" alt="Unanichikitsa" height="24">
                    </a>
                    <ul class="list-unstyled m-0 mt-2">
                        <li class="pb-2 pb-lg-1">
                            ইউনানী, আয়ুর্বেদিক এবং ভেষজ ওষুধের জ্ঞান অর্জন করুন। আমরা বিভিন্ন রোগের ইউনানি, আয়ুর্বেদিক এবং ভেষজ চিকিত্সার উপর ব্যাপক তথ্য প্রদান করেছি, সাথে ব্যবহৃত প্রাকৃতিক ওষুধের বিবরণ সহ। ইউনানি, আয়ুর্বেদিক এবং ভেষজ কীভাবে আপনার স্বাস্থ্যযাত্রাকে সমর্থন করতে পারে তা শিখুন।
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-1"></div>
                <div class="col-6 col-lg-3 pb-4">
                    <div class="mb-1" ><b>Follow Us</b></div>
                    <ul class="list-unstyled m-0">
                        <li class="mb-2"><a href="https://www.facebook.com/unanichikitsa" target="_blank"><i class="bi bi-facebook"></i> Facebook</a></li>
                    </ul>

                    <!-- <div class="mt-4 mb-1 pt-2 pt-lg-0" ><b>Development</b></div>
                    <ul class="list-unstyled m-0">
                        <li class="mb-2"><a href="https://shine-24.web.app/" target="_blank">Shine 24</a></li>
                    </ul> -->
                </div>
                <div class="col-12 col-lg-3 pb-2">
                    <div class="mb-1" ><b>Contact Us</b></div>
                    <div>Gmail: uchikitsa@gmail.com</div>
                    <div>Phone: +8801737354497</div>
                    <!-- <div>Phone 2: +8801786744150</div> -->
                    <div class="pt-3"></div>
                    <div><small><i><b>Contact us from 10:00 AM to 10:00 PM</b></i></small></div>
                </div>
                <div class="col-12 pb-3 pt-3">
                    <p class="m-0">© Copyright unanichikitsa {{ year }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';

export default {
    setup() {
        const year = '2024';

        return { year };
    }
};
</script>

<style lang="scss" scope>
@import "src/_preset.scss";

.container-fluid {
    background-color: #E8F0FE;

    * {
        color: $cs-primary;
        font-size: 18px;
    }

    a {
        text-decoration: none;
        color: $cs-link;
    }

    small {
        font-size: 11px !important;
        position: relative;
        top: -5px;
        letter-spacing: 0.8px;
    }
}
</style>