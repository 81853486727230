<template>
    <div class="px-1">
        <div class="py-4 py-lg-5"></div>
        <br>
        <h1 class="mb-5 text-center software-banifits"><b>সফটওয়্যারটির সুবিধাগুলো নিচে আলোচনা করা হলো:</b></h1>
        <br>
        <div class="row">
            <div class="col-lg-6 pt-4">
                <img src="../../assets/diseases.jpg" alt="Diseases" class="w-100 img-thumbnail">
            </div>
            <div class="col-lg-6 pt-4">
                <h1 class="mb-3"><b>রোগ (Disease)</b></h1>
                <div class="details">
                    আমাদের Unani Chikitsa সফটওয়্যারটি হল একটি বিশাল তথ্যের ভান্ডার যা ইউনানি, আয়ুর্বেদিক এবং ভেষজ
                    চিকিৎসার বিভিন্ন রোগের উপর আলোচনা করা হয়েছে। এখানে মানব দেহের নয়টি তন্ত্র নিয়ে আলাদা আলাদা
                    পরিপূর্ণ অধ্যায় ভিত্তিক বিভিন্ন রোগ সার্স দিয়ে গবেষণার মাধ্যমে বহুল প্রচলিত রোগসমূহ ও তার চিকিৎসা
                    সম্পর্কিত সাজেশন মূলক তথ্য পাওয়া যাবে৷
                </div>

                <router-link to="/registration" class="btn btn-primary mt-4 mt-lg-5">Order Your Software</router-link>
                <br>
                <router-link to="/free-trial" class="btn btn-primary mt-3">7 Days Free Trial</router-link>
            </div>
        </div>

        <div class="py-4 py-lg-5"></div>

        <div class="row">
            <div class="col-lg-6 pt-4 d-lg-none">
                <img src="../../assets/generics.jpg" alt="Generics" class="w-100 img-thumbnail">
            </div>
            <div class="col-lg-6 pt-4">
                <h1 class="mb-3"><b>সাধারণ নাম এবং শক্তি (Generic name and strength)</b></h1>
                <div class="details">
                    Generic name and strength অপশনে সার্চ দিয়ে ওই গ্রুপের ওষুধ সম্পর্কে জানা যায়, কি কি উপাদান দিয়ে
                    তৈরি, ক্রিয়া, প্রয়োগ ক্ষেত্র, প্রয়োগ মাত্রা, বিবিধ জানা যায়৷ জেনেরিক ওষুধের নামও বর্ণনা
                    ক্রমিকভাবে সংঘটিত হয় এবং সেখান থেকে আপনি বিভিন্ন ব্র্যান্ডে যেতে পারেন এবং কোম্পানির নাম অনুসারে
                    সেগুলিকে ফিল্টার করে দেখতে পারেন৷
                </div>

                <router-link to="/registration" class="btn btn-primary mt-4 mt-lg-5">Order Your Software</router-link>
                <br>
                <router-link to="/free-trial" class="btn btn-primary mt-3">7 Days Free Trial</router-link>
            </div>
            <div class="col-lg-6 pt-4 d-none d-lg-block">
                <img src="../../assets/generics.jpg" alt="Generics" class="w-100 img-thumbnail">
            </div>
        </div>

        <div class="py-4 py-lg-5"></div>

        <div class="row">
            <div class="col-lg-6 pt-4">
                <img src="../../assets/brands.jpg" alt="Brands" class="w-100 img-thumbnail">
            </div>
            <div class="col-lg-6 pt-4">
                <h1 class="mb-3"><b>ব্র্যান্ড (Brand)</b></h1>
                <div class="details">
                    আমাদের Unani Chikitsa সফটওয়্যারটিতে ইউনানি, আয়ুর্বেদিক এবং ভেষজ চিকিৎসার জন্য অনেকগুলো ঔষধ সংরক্ষণ
                    করা হয়েছে যা আপনাকে প্রেসক্রিপশন তৈরী করতে সাহায্য করবে। এটি ইউনানী ফার্মাসিটিক্যাল জগতের ৮৪০০ টি
                    জেনেরিক মেডিসিন এর হার্বাল সকল গ্রুপের সকল মেডিসিন সন্ধান করে তথ্য দিতে পারে৷ কোন কোম্পানি কি
                    ব্র্যান্ডে কোন ওষুধ চালায় সব জানা যায়৷
                </div>

                <router-link to="/registration" class="btn btn-primary mt-4 mt-lg-5">Order Your Software</router-link>
                <br>
                <router-link to="/free-trial" class="btn btn-primary mt-3">7 Days Free Trial</router-link>
            </div>
        </div>

        <div class="py-4 py-lg-5"></div>

        <div class="row">
            <div class="col-lg-6 pt-4 d-lg-none">
                <img src="../../assets/patients.jpg" alt="Patients" class="w-100 img-thumbnail">
            </div>
            <div class="col-lg-6 pt-4">
                <h1 class="mb-3"><b>রোগী (Patient)</b></h1>
                <div class="details">
                    Patient option এ রোগীর স্বাস্থ্য রেকর্ড, সহজ পেসেন্ট ডাটাবেজ ম্যানেজমেন্ট এর মাধ্যমে রোগীর রেকর্ড
                    সংরক্ষণ করা যায়, সেখান থেকেই প্রেসক্রিপশন করা যায়৷ মাত্র কয়েক ক্লিকে ডিজিটাল প্রেসক্রিপশন তৈরি করা
                    যায়৷ বারবার ওষুধ লেখার ঝামেলা নাই৷ ত্রুটিমুক্ত প্রেস্ক্রিপশন করা যায়৷
                </div>

                <router-link to="/registration" class="btn btn-primary mt-4 mt-lg-5">Order Your Software</router-link>
                <br>
                <router-link to="/free-trial" class="btn btn-primary mt-3">7 Days Free Trial</router-link>
            </div>
            <div class="col-lg-6 pt-4 d-none d-lg-block">
                <img src="../../assets/patients.jpg" alt="Patients" class="w-100 img-thumbnail">
            </div>
        </div>

        <div class="py-4 py-lg-5"></div>

        <div class="row">
            <div class="col-lg-6 pt-4">
                <img src="../../assets/prescriptions.jpg" alt="Prescriptions" class="w-100 img-thumbnail">
            </div>
            <div class="col-lg-6 pt-4">
                <h1 class="mb-3"><b>প্রেসক্রিপশন (Prescription)</b></h1>
                <div class="details">
                    আমরা আমাদের Unani Chikitsa সফটওয়্যারটিতে রোগীর প্রেসক্রিপশন সংরক্ষণ রাখার ব্যবস্থা করেছি।
                    প্রেসক্রিপশন হারানোর ভয় নাই৷ শেয়ারেবল প্রেসক্রিপশন৷ প্রেসক্রিপশনে cheap complain, diagnosis,
                    investigation, advice automatically আসে তাই সময় কম লাগে৷
                </div>

                <router-link to="/registration" class="btn btn-primary mt-4 mt-lg-5">Order Your Software</router-link>
                <br>
                <router-link to="/free-trial" class="btn btn-primary mt-3">7 Days Free Trial</router-link>
            </div>
        </div>

        <div class="py-4 py-lg-5"></div>

        <h1 class="mb-4"><b>প্রশ্ন ও উত্তর</b></h1>
        <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        এটা কতটা নির্ভরযোগ্য ও বিশ্বাসযোগ্য?
                    </button>
                </div>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        এটা ১০০% নির্ভরযোগ্য ও বিশ্বাসযোগ্য কেননা আমাদের চিকিৎসকগণ ১০০% নির্ভরযোগ্য ও বিশ্বাসযোগ্য উৎস থেকে রিসোর্স করে সম্পাদনা করেছেন।
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        আইটি বুঝে না যারা তাদের জন্য এটা কতটুকু ইউজার ফ্রে ন্ডলি?
                    </button>
                </div>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        এটা ইউজার ফ্রেন্ডলি হবে কেননা এতে মাত্র কয়েক ক্লিকে প্রেসক্রি পশন তৈরি করা যায়৷ কোন ওষুধ সার্স দেওয়া কোন কঠিন ব্যাপার না।
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        এটা শুরু করতে কি ধরনের প্রশিক্ষণ প্রদান করবেন?
                    </button>
                </div>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        পরে যোগ করা হবে।
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                        গ্রামীণ ও প্রত্যন্ত অঞ্চলে ইউনানী চিকিৎসকদের জন্য এটা কতটা অ্যাক্সেস যোগ্য?
                    </button>
                </div>
                <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        অবশ্যই এক্সেস যোগ্য কেননা যেখানে ইন্টারনেট কানেকশন আছে সেখানেই ব্যবহার করা যাবে৷
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                        এটার ব্যবহারের খরচ কেমন?
                    </button>
                </div>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <div class="alert alert-success" v-html="discount"></div>
                        <br>
                        <h5><b>আমাদের প্যাকেজ সমূহ:</b></h5>
                        <ul class="mt-2">
                            <li>৩ মাসের প্যাকেজ এর মূল্য ২৫০ টাকা (250 TK)</li>
                            <li>৬ মাসের প্যাকেজ এর মূল্য ৩৫০ টাকা (350 TK)</li>
                            <li>১ বছরের প্যাকেজ এর মূল্য ৪৫০ টাকা (450 TK)</li>
                            <li>৫ বছরের প্যাকেজ এর মূল্য ১৬৫০ টাকা (1650 TK)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                        এটার কোনো ট্রায়াল পিরিয়ড বা ডেমো অফার করেন কিনা?
                    </button>
                </div>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        আমরা ডেমো অফার করছি। আপনি ভালোভাবে ডেমো চেক করে নিতে পারেন। ডেমো দেখতে <router-link to="/free-trial">এখানে ক্লিক</router-link> করুন। 
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                        কোনো সমস্যা হলে আপনারা কি গ্রাহক সহায়তা দেন?
                    </button>
                </div>
                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        কোনো সমস্যা হলে আমাদের কাস্টমার সাপোর্ট টিম আপনাকে সব ধরণের সহায়তা প্রদান করবে।
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                        এটা ব্যবহার করতে ন্যূনতম কি কি দরকার?
                    </button>
                </div>
                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        মোবাইল বা ল্যাপটপে ব্যবহার করা যায়৷ ইন্টারনেট সংযোগ লাগবে৷ যদি প্রেসক্রিপশন প্রিন্ট করতে চান তাহলে প্রিন্টার সংযোগ দিতে হবে৷
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                        সফটওয়্যারটি কোন ব্রাউসার এ ব্যবহার করবো?
                    </button>
                </div>
                <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        সফটওয়্যারটি ভালোভাবে ব্যবহার করার জন্য Google Chrome ব্রাউসার ব্যবহার করুন।
                    </div>
                </div>
            </div>
        </div>
        <div class="py-5"></div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, onMounted } from 'vue';

export default {
    components: {
        
    },
    setup() {
        const store = useStore();

        const discount = computed(() => store.getters['auth/getDiscount'])
        
        return { discount }
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

h1 {
    font-size: 28px;
}

.details, .accordion {
    font-size: 18px !important;
}

.accordion-button {
    font-size: 18px !important;
    font-weight: 600;
}

a.btn.btn-primary {
    padding-top: 11px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 18px;
}

.software-banifits {
    line-height: 52px;
}
</style>