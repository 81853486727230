<template>
    <div class="container pt-5 px-3">
        <br><br>
        <h4 class="mb-2 title"><b>Plans and Pricing</b></h4>
        <br>
        <div class="row">
            <div class="col-sm-6 col-lg-4 pb-4">
                <div class="card text-center">
                    <div class="card-header py-3">
                        <div class="mb-3">Free</div>
                        <h3 class="m-0"><b>7 Days Free Trial</b></h3>
                    </div>
                    <div class="card-body">
                        <table class="w-100">
                            <tr>
                                <td class="text-start pb-2">Diseases</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Generic name and strength</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Brands</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Patients</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Prescriptions</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Save to cart  (Medicine)</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Cart to prescription (Medicine)</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start">Life time support</td>
                                <td class="text-end"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                        </table>
                    </div>
                    <div class="card-footer p-0">
                        <router-link to="/free-trial" class="btn btn-primary w-100">Start Free Trial</router-link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 pb-4">
                <div class="card text-center">
                    <div class="card-header py-3">
                        <div class="mb-3">Pro</div>
                        <h3 class="m-0"><b>250 - 1650 (TK)</b></h3>
                    </div>
                    <div class="card-body">
                        <table class="w-100">
                            <tr>
                                <td class="text-start pb-2">Diseases</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Generic name and strength</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Brands</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Patients</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Prescriptions</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Save to cart  (Medicine)</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start pb-2">Cart to prescription (Medicine)</td>
                                <td class="text-end pb-2"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                            <tr>
                                <td class="text-start">Life time support</td>
                                <td class="text-end"><i class="bi bi-check-circle-fill text-success"></i></td>
                            </tr>
                        </table>
                    </div>
                    <div class="card-footer p-0">
                        <router-link to="/registration" class="btn btn-primary w-100">Buy Plan</router-link>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
    </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';

export default {
    setup() {

        return {};
    }
};
</script>

<style lang="scss" scope>
@import "src/_preset.scss";

.container {
    .card-body * {
        color: $cs-primary;
        font-size: 18px;
    }

    a {
        text-decoration: none;
        color: $cs-link;
    }

    table th, td {
        vertical-align: top;
    }

    a.btn.btn-primary {
        padding-top: 15px;
        height: 58px;
        font-size: 18px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
</style>