import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/home/Home.vue'
import Registration from '@/components/order-and-registration/Registration.vue'
import CompleteNewOrder from '@/components/order-and-registration/CompleteNewOrder.vue'
import ActivePlan from '@/components/order-and-registration/ActivePlan.vue'
import CompleteRenewOrder from '@/components/order-and-registration/CompleteRenewOrder.vue'
import PlansAndPricing from '@/components/plans-and-pricing/PlansAndPricing.vue'
import FreeTrial from '@/components/order-and-registration/FreeTrial.vue'
import CompleteFreeTrial from '@/components/order-and-registration/CompleteFreeTrial.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/free-trial',
        name: 'Free Trial',
        component: FreeTrial
    },
    {
        path: '/complete-free-trial',
        name: 'Complete Free Trial',
        component: CompleteFreeTrial
    },
    {
        path: '/plans-and-pricing',
        name: 'Plans and Pricing',
        component: PlansAndPricing
    },
    {
        path: '/registration',
        name: 'Registration',
        component: Registration
    },
    {
        path: '/active-plan',
        name: 'Active Plan',
        component: ActivePlan
    },
    {
        path: '/complete-new-order',
        name: 'Complete New Order',
        component: CompleteNewOrder
    },
    {
        path: '/complete-renew-order',
        name: 'Complete Renew Order',
        component: CompleteRenewOrder
    },
    { 
        path: '/:pathMatch(.*)*', 
        component: Home 
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
