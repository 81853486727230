<template>
    <div id="page-header">
        <nav class="navbar navbar-expand-lg fixed-top navbar-light py-0">
            <div class="container px-3">
                <a href="#" class="navbar-brand pt-0">
                    <img src="../../assets/logo.png" alt="Unanichikitsa" height="28">
                </a>
                <ul class="navbar-nav ms-auto mb-0">
                    <li class="nav-item d-none d-lg-inline-block">
                        <router-link class="nav-link nav-item-padding" to="/home">Home</router-link>
                    </li>
                    <li class="nav-item d-none d-lg-inline-block">
                        <router-link class="nav-link nav-item-padding" to="/registration">Order Software</router-link>
                    </li>
                    <li class="nav-item d-none d-lg-inline-block">
                        <router-link class="nav-link nav-item-padding" to="/active-plan">Renew Package</router-link>
                    </li>
                    <li class="nav-item d-none d-lg-inline-block">
                        <router-link class="nav-link nav-item-padding" to="/plans-and-pricing">Plans & Pricing</router-link>
                    </li>
                </ul>
                <div class="text-end menu-icon d-inline-block d-lg-none">
                    <a @click="openNav" href="#" >Menu</a>
                </div>
            </div>
        </nav>

        <div id="overlay" class="overlay" @click="closeNav($event)">
            <div @click="selectNavMenu" class="close-menu">
                <i class="bi bi-x-circle"></i>
            </div>
            <div id="overlay-content" class="overlay-content">
                <div class="list-group rounded-0">
                    <router-link @click="selectNavMenu($event)" class="list-group-item list-group-item-action py-3"
                        to="/home">Home</router-link>
                    <router-link @click="selectNavMenu($event)" class="list-group-item list-group-item-action py-3"
                    to="/registration">Order Software</router-link>
                    <router-link @click="selectNavMenu($event)" class="list-group-item list-group-item-action py-3"
                    to="/active-plan">Renew Package</router-link>
                    <router-link @click="selectNavMenu($event)" class="list-group-item list-group-item-action py-3"
                    to="/plans-and-pricing">Plans & Pricing</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';

export default {
    setup() {

        function openNav() {
            document.getElementById("overlay").style.display = "block";
        }

        function closeNav(event) {
            if (event.target.className == 'overlay') {
                document.getElementById("overlay").style.display = "none";
            }
        }

        function selectNavMenu() {
            document.getElementById("overlay").style.display = "none";
        }

        return {
            openNav,
            closeNav,
            selectNavMenu
        }
    }
}
</script>

<style lang="scss" scope>
@import "src/_preset.scss";

.fixed-top {
    z-index: 4 !important;
}

.navbar {
    background-color: $cs-white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    height: 60px;

    * {
        color: $cs-primary !important;
    }

    a {
        text-decoration: none;
    }

    .navbar-brand {
        padding-top: 2px !important;
    }

    .menu-icon {
        cursor: pointer;
        padding-top: 15px;
        padding-bottom: 10px;
        padding-left: 10px;
        font-weight: 600 !important;
    }

    .nav-link {
        font-weight: 600;
        border-bottom: 2px solid white
    }

    .nav-item-padding {
        padding-top: 1.2rem !important;
        padding-bottom: 0.9rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .router-link-active {
        border-bottom: 2px solid $cs-link;
    }
}

.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.466);
    overflow: hidden;
    transition: 0.3s;
    display: none;

    .navbar-brand {
        margin-top: 6px;
    }

    .close-menu {
        display: none;
        position: absolute;
        top: 10px;
        right: 18px;
        color: $cs-white;
        font-size: 35px;
        cursor: pointer;
    }

    .overlay-content {
        background-color: $cs-white;
        width: 80%;
        height: 100%;
        position: relative;
        a.router-link-active.router-link-exact-active.list-group-item.list-group-item-action{
            background-color: #179BE8 !important;
            color: $cs-white !important;
        }

        .profile-picture {
            border-radius: 50%;
        }

        .list-group-item {
            border: none !important;
        }
    }

    @media (min-width: 500px) {
        .overlay-content {
            width: 360px !important;
        }
        .close-menu {
            display: block !important;
        }
    }

    .py-3 {
        padding-top: 0.8rem !important;
        padding-bottom: 0.8rem !important;
    }
}
</style>